export const TICKET_STATUS = {
  new: "primaryInverse",
  resolved: "successInverse",
  in_progress: "greyBorder",
};
export const PRIORITY_STATUS = {
  low: "#8585A3",
  normal: "#FFB72D",
  high: "#D61E34",
};
export const STATUS = {
  ongoing: "primaryInverse",
  completed: "successInverse",
  cancelled: "errorInverse",
  deleted: "errorInverse",
  active: "successInverse",
  disabled: "errorInverse",
  withdrawn: "errorInverse",
  failed: "errorInverse",
  successful: "successInverse",
  pending: "blueLight",
  declined: "errorInverse",
  suspended: "default",
  deadline_failure: "errorInverse",
};

export const STATUS2 = {
  ongoing: "primaryInverse",
  open: "primaryInverse",
  closed: "errorInverse",
  "in progress": "primaryInverse",
  // ongoing: "primaryInverse",
  new: "primaryInverse",
  rejected: "errorInverse",
  refunded: "primaryInverse",
  approved: "successInverse",
  completed: "successInverse",
  archived: "greyBorder",
  promoted: "primaryInverse",
  cancelled: "errorInverse",
  terminated: "errorInverse",
  exited: "errorInverse",
  expired: "errorInverse",
  deleted: "errorInverse",
  active: "primaryInverse",
  inactive: "errorInverse",
  disabled: "errorInverse",
  failed: "errorInverse",
  drafted: "greyBorder",
  successful: "successInverse",
  pending: "blueLight",
  declined: "errorInverse",
  suspended: "errorInverse",
  escrow: "greyBorder",
  withdrawn: "errorInverse",
};

export const STATUS3 = {
  ongoing: "primaryInverse",
  completed: "successInverse",
  cancelled: "errorInverse",
  deleted: "errorInverse",
  active: "successInverse",
  disabled: "errorInverse",
  failed: "errorInverse",
  successful: "successInverse",
  pending: "blueLight",
  declined: "errorInverse",
  suspended: "greyBorder",
};
// chart options
export const plotOptions = (total, series, totalLabel) => ({
  pie: {
    donut: {
      size: "74%",
      labels: {
        show: true,
        name: {
          show: true,
          fontSize: "14px",
          fontWeight: 500,
          color: "black",
          offsetY: 24,
          formatter: function (val) {
            return `${val}`;
          },
        },
        value: {
          show: true,
          fontSize: "20px",
          fontWeight: 500,
          offsetY: -20,
          formatter: function (val, opts) {
            return `${Math.round((val / total) * 100)}%`;
          },
        },
        total: {
          show: true,
          label: totalLabel,
          fontFamily: "Montserrat, sans-serif",
          formatter: () =>
            `${series.reduce((a, b) => {
       return a + b;
      }, 0)}`,
        },
      },
    },
  },
});

export const LegendOptions = {
  position: "bottom",
  horizontalAlign: "left",
  fontSize: "16px",
  fontWeight: 500,
  markers: {
    offsetX: -12,
  },
  itemMargin: {
    horizontal: 3,
    vertical: 10,
  },
};

export const IS_DEV_MODE = window.location.origin.includes("staging") || process.env.NODE_ENV === "development";

export const API_URL = IS_DEV_MODE ?
  `${process.env.REACT_APP_STAGING_API_URL}/v2` :
  `${process.env.REACT_APP_PRD_API_URL}/v2`;

  export const PUSHER_KEY = IS_DEV_MODE?
  process.env.REACT_APP_STAGING_PUSHER_KEY :
  process.env.REACT_APP_PRD_PUSHER_KEY;