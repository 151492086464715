import {Tooltip} from "@mui/material";


function OnlineCircle({
  TooltipTitle = "Online",
  tooltipPlacement = "right-start",
  ...props
}) {
  return (
    <Tooltip title={TooltipTitle || ""} placement={tooltipPlacement}>
      <span style={{width: 10, height: 10, borderRadius: '50%', display: 'inline-block', backgroundColor: '#A5D6A7'}}></span>
    </Tooltip>
  );
}

export default OnlineCircle;
