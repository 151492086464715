import { Chip, IconButton } from "@mui/material";
import StatCard from "components/StatCard";
import MyDataTable from "components/TableComponent/MyDataTable";
import UserDisplay from "components/TableComponent/UserDisplay";
import InfluencerIcon from "components/Vectors/InfluencerIcon";
import { Link } from "react-router-dom";
import { STATUS, PUSHER_KEY } from "utils/constants";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import { useGetInfluencerStatsQuery, useGetInfluencersQuery } from "services";
import moment from "moment";
import Pusher from "pusher-js";
import { getUserDetails } from "utils";
import { useState, useEffect } from "react";

const AdminInfluencers = () => {
 const JOBS_PER_PAGE = 10;
 const [page, setPage] = useState(1);
 const [perPage, setPerPage] = useState(JOBS_PER_PAGE);

 const {
  data: allInfluencers,
  isLoading,
  error,
 } = useGetInfluencersQuery({
  params: { is_paginated: "yes", page, per_page: perPage },
 });

 const { data: allInfluencerData, isLoading: isLoadingAllInfluencers } = useGetInfluencersQuery({
  params: { is_paginated: "no" },
 });

 const [influencers, setInfluencers] = useState([]);
 const [userStatus, setUserStatus] = useState({});

 // Pusher setup for real-time updates
 useEffect(() => {  
  const pusher = new Pusher(PUSHER_KEY, {
   cluster: "mt1",
   encrypted: true,
  });

  const channel = pusher.subscribe("user-logged-in");
  channel.bind("user-login-event", (data) => {
   console.log(`From Pusher Login - ${data}`);
   setUserStatus((prev) => ({ ...prev, [data.id]: "online" }));
  });

  const channelLogout = pusher.subscribe("user-logged-out");
  channelLogout.bind("user-logout-event", (data) => {
   console.log(`From Pusher Logout - ${data}`);
   setUserStatus((prev) => ({ ...prev, [data.id]: "offline" }));
  });

  return () => {
   pusher.unsubscribe("user-logged-in");
   pusher.unsubscribe("user-logged-out");
  };
 }, []);

 useEffect(() => {
  if (allInfluencers?.data?.data) {
   const initialInfluencers = allInfluencers.data?.data?.filter((influencer) => influencer);
   setInfluencers(initialInfluencers);
  }
 }, [allInfluencers]);

 useEffect(() => {
  if (allInfluencerData?.data) {
   const initialOnlineInfluencers = allInfluencerData?.data?.filter((influencer) => influencer.is_online == 1);
   let formattedOnlineInfluencers = [];
   initialOnlineInfluencers.forEach((onlineInfluencer) => {
    formattedOnlineInfluencers[onlineInfluencer.id] = "online";
   });
   setUserStatus((prev) => ({ ...prev, ...formattedOnlineInfluencers }));
  }
 }, [allInfluencerData]);

 const { data: allInfluencerStats } = useGetInfluencerStatsQuery();

 const searchFunc = (value) => {
  const matchedInfluencers = allInfluencers.data?.data?.filter((influencer) =>
   influencer?.first_name?.toLowerCase().includes(value.toLowerCase()),
  );

  setInfluencers(matchedInfluencers);
 };

 const options = {
  searchOpen: true,
  viewColumns: false,
  filter: true,
  serverSide: true,
  selectableRows: "none",
  count: allInfluencers?.data?.total,
  rowsPerPage: perPage,
  onTableChange: (action, tableState) => {
   switch (action) {
    case "changePage":
     setPage(tableState.page + 1);
     break;
    case "changeRowsPerPage":
     setPerPage(tableState.rowsPerPage);
     break;
    default:
     break;
   }
  },
 };

 const statCards = [
  // {
  //   name: "All Influencers",
  //   value: allInfluencerStats?.data?.total,
  //   iconBg: "bg-[#FAF5FF]",
  //   icon: <InfluencerIcon width={32} height={32} color="#8937CE" />,
  //   percentage: null,
  // },
  {
   name: "Active Influencers",
   value: allInfluencerStats?.data?.active,
   iconBg: "bg-success-100",
   icon: <InfluencerIcon width={32} height={32} color="#00A04A" />,
   percentage: null,
  },
  {
   name: "Inactive Influencers",
   value: allInfluencerStats?.data?.inactive,
   iconBg: "bg-gray-100",
   icon: <InfluencerIcon width={32} height={32} color="#8585A3" />,
   percentage: null,
  },
  {
   name: "Suspended Influencers",
   value: allInfluencerStats?.data?.suspended,
   iconBg: "bg-[#D61E341A]",
   icon: <InfluencerIcon width={32} height={32} color="#D61E34" />,
   percentage: null,
  },
 ];

 const data = influencers?.map((influencer, i) => ({
  id: (allInfluencers?.data?.current_page - 1) * allInfluencers?.data?.per_page + (i + 1),
  user: {
   id: String(influencer?.id),
   name: getUserDetails(influencer).name || "N/A",
   type: influencer?.username ? "@" + influencer?.username : "",
   img: influencer?.profile_image,
   kyc_verified: influencer?.kyc_verified,
   email: influencer?.email,
   number: influencer?.phone_number,
   salesperson: influencer?.salesperson?.first_name ?? null,
   is_online: influencer?.is_online,
  },
  location: influencer.state?.name ? influencer.state?.name + ", " + influencer?.country?.name : "N/A",
  completed_tasks: influencer?.influencer_completed_tasks_count,
  failed_tasks: influencer?.influencer_failed_tasks_count,
  ongoing_tasks: influencer?.influencer_ongoing_tasks_count,
  joined: {
   name: moment(influencer.created_at).format("MMM DD, YYYY h:m a"),
   ago: moment(influencer.created_at).fromNow(),
  },
  status: influencer?.deleted_at ? "SOFT DELETE" : influencer.status,
  actions: String(influencer.id),
 }));

 const fullInfluencersData = allInfluencerData?.data
  ?.filter((influencer) => influencer)
  ?.map((influencer, i) => ({
   id: i + 1,
   user: {
    id: String(influencer?.id),
    name: getUserDetails(influencer).name || "N/A",
    type: influencer?.username ? "@" + influencer?.username : "",
    img: influencer?.profile_image,
    kyc_verified: influencer?.kyc_verified,
    email: influencer?.email,
    number: influencer?.phone_number,
   },
   location: influencer.state?.name ? influencer.state?.name + ", " + influencer?.country?.name : "N/A",
   completed_tasks: influencer?.influencer_completed_tasks_count,
   failed_tasks: influencer?.influencer_failed_tasks_count,
   ongoing_tasks: influencer?.influencer_ongoing_tasks_count,
   joined: {
    name: moment(influencer.created_at).format("MMM DD, YYYY h:m a"),
    ago: moment(influencer.created_at).fromNow(),
   },
   status: influencer?.deleted_at ? "SOFT DELETE" : influencer.status,
   actions: String(influencer.id),
  }));

 const headCells = [
  {
   name: "id",
   label: <p className="truncate flex text-center first-letter:font-medium text-prop text-sm">ID</p>,
   options: {
    customBodyRender: (value) => <p className="w-full ml-[-5px] text-center ">{value}</p>,
   },
  },

  {
   name: "user",
   label: "influencer",
   options: {
    customBodyRender: (value, tableMeta) => {
     const userId = data[tableMeta.rowIndex].user.id;
     const isOnline = userStatus[userId]
      ? userStatus[userId] === "online"
      : value?.is_online === 1
      ? value?.is_online
      : null;
     return (
      <UserDisplay
       name={value?.name}
       email={value?.type}
       img={value?.img}
       badge={value?.kyc_verified}
       salesperson={value?.salesperson}
       online={isOnline}
      />
     );
    },
   },
  },

  {
   name: "location",
   label: "Location",
   options: {
    customBodyRender: (value) => <p className="flex font-medium text-prop text-sm">{value}</p>,
   },
  },

  {
   name: "joined",
   label: "Date Joined",
   options: {
    customBodyRender: (value) => (
     <div>
      <p>{value?.name}</p>
      <p className="text-xs text-grey ">{value?.ago}</p>
     </div>
    ),
   },
  },
  {
   name: "completed_tasks",
   label: "completed Tasks",
   options: {
    customBodyRender: (value) => <p className="flex font-medium text-prop text-sm">{value}</p>,
   },
  },
  {
   name: "failed_tasks",
   label: "failed Tasks",
   options: {
    customBodyRender: (value) => <p className="flex font-medium text-prop text-sm">{value}</p>,
   },
  },
  {
   name: "ongoing_tasks",
   label: "Ongoing Tasks",
   options: {
    customBodyRender: (value) => <p className="flex font-medium text-prop text-sm">{value}</p>,
   },
  },

  {
   name: "status",
   label: "status",
   options: {
    filter: true,
    customBodyRender: (value) => (
     <Chip
      color={`${value === "SOFT DELETE" ? "error" : STATUS[value?.toLowerCase()] || "default"}`}
      variant={value === "SOFT DELETE" ? "outlined" : "contained"}
      sx={{ minWidth: 100, borderRadius: "20px" }}
      label={value}
     />
    ),
   },
  },

  {
   name: "actions",
   label: "actions",
   options: {
    customBodyRender: (value) => (
     <IconButton to={value} component={Link}>
      <RemoveRedEyeOutlinedIcon />
     </IconButton>
    ),
   },
  },
 ];

 return (
  <div className="h-full p-6 lg:px-10 px-4 bg-grey100">
   <div className="flex justify-between items-center mb-4">
    <h1 className="title">Influencers</h1>
   </div>

   {/* Cards */}
   <div className="grid-cols-2 lg:grid-cols-3 grid gap-4">
    {statCards.map((statCard, index) => (
     <StatCard key={index} {...statCard} />
    ))}
   </div>

   {/* Table */}
   <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-8"></div>
   <div className="flex justify-between mt-8">
    <h5 className="text-2xl font-semibold">All Influencers</h5>
   </div>

   <div className="p-4 bg-white py-6 border rounded-xl mt-5">
    <MyDataTable
     options={options}
     headCells={headCells}
     isLoading={isLoading}
     error={error}
     data={data}
     isLoadingAll={isLoadingAllInfluencers}
     dataAll={fullInfluencersData}
     type="users"
     searchFunc={searchFunc}
     emptyData={{
      icon: InfluencerIcon,
      message: "No Influencers",
      extraText: "There are no influencers yet.",
     }}
     searchPlaceholder="Search influencers..."
    />
   </div>
  </div>
 );
};

export default AdminInfluencers;
